import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { CookiesUtilsService } from 'app/services/cookies-utils/cookies-utils.service';
import { environment } from 'environments/environment';
import { AuthenticationResponse } from 'app/core/models/authentication-response';
import { StorageUtilsService } from 'app/services/storage-utils/storage-utils.service';
import { CandidateModel } from 'app/core/models/candidate.model';
import { isPlatformBrowser } from '@angular/common';
import { Constants } from 'app/core/data/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  _platformId = inject(PLATFORM_ID)

  constructor(
    private _httpClient: HttpClient,
    private _cookiesUtilsService: CookiesUtilsService,
    private _router: Router,
    private _storageUtilsService: StorageUtilsService,
  ) { }

  /* login password method */
  signIn({ credentials }: { credentials: { username: string, password: string, rememberMe: boolean } }) {
    return this._httpClient.post<any>(environment.ENDPOINTS.LOGIN_URL, credentials).pipe(
      switchMap((response: any) => {

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  /** candidate reigstration */
  candidateSignUp({ candidateCreds }: {
    candidateCreds: {
      login?: string;
      password?: string;
      confirm?: string;
    }
  }): Observable<any> {
    return this._httpClient.post<any>(`${environment.ENDPOINTS.CANDIDATE_REGISTER}`, candidateCreds);
  }

  /** candidate activation */
  candidateActivation({candidate,queryParams}:{candidate:CandidateModel,queryParams:Map<string,string>}) : Observable<CandidateModel>{
    let httpParams = new HttpParams();
    queryParams?.forEach((value: any, key: string) => {
      if (value !== null && value !== undefined  && value !== '')
        httpParams = httpParams.append(key, `${value}`);
    })
    return this._httpClient.post<any>(`${environment.ENDPOINTS.CANDIDATE_REGISTER}`, candidate,{params:httpParams}); of()
  }

  /* register password method */
  signUp({ signUpCred }: { signUpCred: any }): Observable<any> {
    const url: string = `${environment.ENDPOINTS.REGISTER_URL}`;
    return this._httpClient.post<any>(
      url,
      signUpCred,
      {
        responseType: 'text' as 'json',
      }
    );
  }

  /* forgot password method */
  forgotPassword({ email }: { email: string }): Observable<any> {
    const url: string = `${environment.ENDPOINTS.FORGOT_PASSWORD_URL}`;
    return this._httpClient.post<any>(
      url,
      email
    );
  }

  resetPassword({ resetDto }: {
    resetDto: {
      newPassword: string,
      confirm: string,
      key: string,
    }
  }): Observable<any> {
    const url: string = `${environment.ENDPOINTS.RESET_PASSWORD_URL}`;
    return this._httpClient.post<any>(
      url,
      resetDto
    );
  }

  /*  logout method */
  signOut() {
    this._cookiesUtilsService.removeAccessToken();
    //this._router.navigate([Constants.HOME_ROUTE]);
  }

  checkIsLoggedIn(): Observable<boolean | null> {
    const accessToken = this._cookiesUtilsService.getAccessToken();
    // if (isPlatformBrowser(this._platformId)) {
          // check if user has access token
      if (accessToken && accessToken !== '' && accessToken !== 'undefined' && accessToken !== 'null' ) {
        return of(true);
      } else {
        return of(false);
      }
    // }
    return of(null)
  }


  oauth2({ source, authenticateType,redirectionUrl }: { source: string, authenticateType: string, redirectionUrl?:string }) {
    let oauth2Url = ''
    let httpParams = new HttpParams();
    if(authenticateType){
      httpParams=  httpParams.append('authenticateType',authenticateType)
    }
    if(redirectionUrl){
      httpParams = httpParams.append('redirectionUrl',redirectionUrl)
    }
    switch (source) {
      case Constants.LINKED_IN:
        oauth2Url = environment.ENDPOINTS['LINKED_IN_OAUTH_URL'];
        break;
      case Constants.GOOGLE:
        oauth2Url = environment.ENDPOINTS['GOOGLE_OAUTH_URL'];
        break;
    }
    return this._httpClient.get<any>(
      oauth2Url, {params : httpParams}
    );
  }

  sso({ source, ssoToken }: { source: string, ssoToken: string}) {
    let ssoUrl = ''
    switch (source) {
      case Constants.LINKED_IN:
        ssoUrl = environment.ENDPOINTS['LINKED_IN_SSO_URL'] + `?code=${ssoToken}`;
        break;
      case Constants.GOOGLE:
        ssoUrl = environment.ENDPOINTS['GOOGLE_SSO_URL'] + `?idToken=${ssoToken}`;
        break;
    }
    return this._httpClient.post<any>(
      ssoUrl,
      {}
    );
  }

}
